import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HomeIcon from '@material-ui/icons/Home'
import FavoriteIcon from '@material-ui/icons/Favorite'
import InsideOutProjectLogo from '../../images/commons/InsideOutProjectLogo.jpg'

const ActionPage = () => (
  <Layout>
    <SEO title="Action" />
    <h1>Action</h1>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '120px'
      }}
    >
      <a href="https://www.simpsonhousing.org/donate-now/">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <FavoriteIcon style={{ fontSize: 32, color: 'white' }} />
          <h2 style={{ margin: '0 1rem 0 1rem' }}>
            Donate to Simpson Housing Services
          </h2>
          <HomeIcon style={{ fontSize: 32, color: 'white' }} />
        </div>
      </a>
      <a href="https://www.insideoutproject.net/en">
        <img
          style={{ maxWidth: '150px', marginTop: '120px' }}
          src={InsideOutProjectLogo}
          alt="Inside Out Project"
        />
      </a>
    </div>
  </Layout>
)

export default ActionPage
